import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Deal from './Templates/Deal';
import Manager from './Templates/Manager';
import Dashboard from './Templates/Dashboard';
import Reviews from './Templates/Reviews';
import Newjoiners from './Templates/Newjoiners';
import Nodeals from './Templates/Nodeals';
import Birthday from './Templates/Birthday';
import Birthday2 from './Templates/Birthday2';
import OneByOneManagers from './Templates/OneByOneManagers';

const oneByOneIndexes = {};

export default function Slides() {
  const { id } = useParams();
  const [slide, setSlide] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalSlides, setTotalSlides] = useState(0);
  const [duration, setDuration] = useState(20000);
  const navigate = useNavigate();

  // 1. Загружаем данные слайда
  useEffect(() => {
    const fetchSlide = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://dash2.axc.ae/api/slide/${id}`);
        const data = await response.json();

        if (data && data.active) {
          setSlide(data);
          setDuration(data.duration || 20000);
        } else {
          goToNextSlide(parseInt(id, 10) + 1);
        }
      } catch (error) {
        console.error('Ошибка:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchSlide();
  }, [id]);

  // 2. Загружаем общее количество слайдов
  useEffect(() => {
    const fetchTotalSlides = async () => {
      try {
        const response = await fetch('https://dash2.axc.ae/api/slides/count');
        const data = await response.json();
        setTotalSlides(data.count);
      } catch (error) {
        console.error('Ошибка при получении количества слайдов:', error);
      }
    };
    fetchTotalSlides();
  }, []);

  // 3. Функция перехода на следующий
  const goToNextSlide = (nextId) => {
    if (totalSlides > 0) {
      const nextSlideId = ((nextId - 1) % totalSlides) + 1;
      console.log(`Current ID: ${id}, Next ID: ${nextId}, Total Slides: ${totalSlides}, Calculated Next Slide ID: ${nextSlideId}`);
      navigate(`/${nextSlideId}`);
    }
  };

  // 4. Таймер автоперехода. 
  //    Подключаем только когда уже знаем totalSlides > 0 и не идёт загрузка.
  useEffect(() => {
    if (loading || totalSlides === 0) return;

    const interval = setInterval(() => {
      goToNextSlide(parseInt(id, 10) + 1);
    }, duration);

    return () => clearInterval(interval);
  }, [loading, id, totalSlides, duration]);

  // 5. При смене `slide` (а значит, и при смене `id`) — если это `onebyoneManagers`, то инкрементируем индекс
  useEffect(() => {
    if (!slide) return;
    if (slide.type === 'onebyoneManagers') {
      const managersObj = JSON.parse(slide.data || '{}');
      const keys = Object.keys(managersObj).sort((a, b) => parseInt(a) - parseInt(b));
      const totalManagers = keys.length;
      if (totalManagers > 0) {
        if (oneByOneIndexes[slide.id] === undefined) {
          oneByOneIndexes[slide.id] = 0;
        } else {
          // Инкрементируем ТОЛЬКО один раз при "заходе" на слайд
          oneByOneIndexes[slide.id] = (oneByOneIndexes[slide.id] + 1) % totalManagers;
        }
        console.log(`(Effect) oneByOneIndex = ${oneByOneIndexes[slide.id]} for slide.id=${slide.id}`);
      }
    }
  }, [slide]);

  // 6. Рендер
  const renderSlide = (slide) => {
    switch (slide.type) {
      case 'deal':
        return <Deal data={slide.data} />;
      case 'secondaryManagers':
      case 'primaryManagers':
      case 'axcapitalOrgchart':
      case 'axholdingOrgchart':
      case 'training':
        return <Manager type={slide.type} />;
      case 'dashboard':
        return <Dashboard data={slide.data} />;
      case 'reviews':
        return <Reviews data={slide.data} />;
      case 'newjoiners':
        return <Newjoiners data={slide.data} />;
      case 'birthday':
        return <Birthday data={slide.data} />;
      case 'birthday2':
        return <Birthday2 data={slide.data} />;
      case 'nodeals':
        return <Nodeals data={slide.data} />;

      case 'onebyoneManagers': {
        // Получаем индекс, НО уже не инкрементируем
        let managersObj = {};
        try {
          managersObj = JSON.parse(slide.data || '{}');
        } catch (e) {
          console.error('Ошибка парсинга oneByOne:', e);
        }
        const keys = Object.keys(managersObj).sort((a, b) => parseInt(a) - parseInt(b));
        const index = oneByOneIndexes[slide.id] ?? 0;
        const fileName = keys.length ? managersObj[keys[index]] : null;

        if (!fileName) {
          return <div>Нет менеджеров</div>;
        }
        console.log(`(render) index=${index}, fileName=${fileName} for slide ${slide.id}`);

        return <OneByOneManagers fileName={fileName} />;
      }

      default:
        return <div>Неизвестный тип слайда</div>;
    }
  };

  // 7. Деактивация некоторых слайдов
  useEffect(() => {
    const updateSlideState = async () => {
      if (slide && [3, 5, 7, 9].includes(slide.order_num)) {
        try {
          await fetch('https://dash2.axc.ae/api/deactivate-slide', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: slide.id, active: 0 })
          });
        } catch (error) {
          console.error('Ошибка при обновлении слайда:', error);
        }
      }
    };
    updateSlideState();
  }, [slide]);

  // 8. Если идёт загрузка
  if (loading) {
    return <div>Загрузка...</div>;
  }
  if (!slide) {
    return <div className='text-bg-danger'>Not active slide</div>;
  }

  return (
    <div>
      {renderSlide(slide)}
    </div>
  );
}
