import React from 'react';

export default function OneByOneManagers({ fileName }) {
  let imagePath;

  try {
    imagePath = require(`../../Assets/Images/oboManagers/${fileName}`);
  } catch (error) {
    console.error(`Файл ${fileName} не найден`);
    imagePath = ''; // Можно указать путь к заглушке
  }

  return (
    <div style={{ height: '100vh' }}>
      {imagePath ? (
        <img
          src={imagePath}
          alt="Manager"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        />
      ) : (
        <p>Изображение не найдено</p>
      )}
    </div>
  );
}